<template>
    <div class="full-wrapper">
        <CustomCard
            :props="{
                width: 500,
                outlined: true,
                loading: loading ? '#F88D4C' : false,
            }">
            <span slot="card-title">{{$t('message.signIn')}}</span>
            <div slot="card-body">
                <v-form ref="signInForm"
                        @submit.prevent="submit"
                        class="auth-form signin-form"
                >
                    <div class="auth-form__body">
                        <div class="form-group textfield">
                            <v-text-field
                                    outlined
                                    required
                                    v-model="email"
                                    :rules="[
                                    _rules.required,
                                    _rules.email,
                                ]"
                                    :placeholder="$t('message.email')"
                            ></v-text-field>
                            <label class="label" for="event">
                                {{$t('message.email')}}
                                <i class="warning-icon">!</i>
                            </label>
                        </div>
                        <div class="form-group textfield">
                            <v-text-field
                                    outlined
                                    v-model="password"
                                    type="password"
                                    :rules="[
                                        _rules.required,
                                        _rules.password,
                                    ]"
                                    :placeholder="$t('message.password')"
                                    required
                            ></v-text-field>
                            <label class="label" for="event">
                                {{$t('message.password')}}
                                <i class="warning-icon">!</i>
                            </label>
                            <router-link to="forgot-password" class="link forgot-password">
                                {{$t('message.forgotPassword')}}
                            </router-link>
                        </div>
                    </div>
                    <div class="auth-form__actions signin-form__actions">
                        <ButtonSuccess
                            :props="{disabled: loading}"
                            type="submit"
                            class="auth-form__submit">
                            {{$t('message.login')}}
                        </ButtonSuccess>
                        <a target="_blank" :href="eClassUrl"
                           class="link">
                            {{$t('message.signInByEclass')}}
                        </a>
                    </div>
                </v-form>
            </div>
        </CustomCard>
    </div>
</template>

<script>
import ButtonSuccess from '@components/Shared/Buttons/ButtonSuccess.vue';
import CustomCard from '@components/Shared/CustomCard.vue';
import rulesMixin from '@/mixins/Form/rules';

export default {
  name: 'SignIn',
  components: {
    ButtonSuccess,
    CustomCard,
  },
  mixins: [rulesMixin],
  data() {
    return {
      email: '',
      password: '',
      loading: false,
      eClassConf: {
        id: process.env.VUE_APP_E_CLASS_CLIENT_ID,
        url: process.env.VUE_APP_E_CLASS_REDIRECT_URI,
      },
    };
  },
  computed: {
    eClassUrl() {
      return `https://my.e-klase.lv/Auth/OAuth/?client_id=${this.eClassConf.id}&redirect_uri=${this.eClassConf.url}`;
    },
  },
  methods: {
    submit() {
      const { email, password } = this;
      if (this.formValidate(this.$refs.signInForm)) {
        this.loading = true;
        this.$store.dispatch('auth/login', {
          email,
          password,
        }).then(() => {
          this._showSuccessNotify(this.$t('message.loginSuccess'));
          this.$store.dispatch('auth/auth').then(() => {
            window.location.reload();
          }).catch((e) => {
            console.log(e);
            this.loading = false;
          });
        }).catch((e) => {
          const { data } = e.response;
          this._showErrorNotify(data.error);
          this.loading = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
    .forgot-password{
        font-size: 12px;
        position: absolute;
        right: 0;
        bottom: 2px;
    }
</style>
